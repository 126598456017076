<template>
  <svg
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  ><rect
    width="300"
    height="300"
    rx="40"
    fill="#0050F5"
  /><path
    d="M58 76.5C58 66.283 66.283 58 76.5 58S95 66.283 95 76.5v148c0 10.217-8.283 18.5-18.5 18.5S58 234.717 58 224.5v-148ZM206 76.5c0-10.217 8.283-18.5 18.5-18.5S243 66.283 243 76.5v148c0 10.217-8.283 18.5-18.5 18.5s-18.5-8.283-18.5-18.5v-148ZM132 76.5c0-10.217 8.283-18.5 18.5-18.5S169 66.283 169 76.5v37c0 10.217-8.283 18.5-18.5 18.5s-18.5-8.283-18.5-18.5v-37ZM132 187.5c0-10.217 8.283-18.5 18.5-18.5s18.5 8.283 18.5 18.5v37c0 10.217-8.283 18.5-18.5 18.5s-18.5-8.283-18.5-18.5v-37Z"
    fill="#fff"
  /><path
    d="M95.168 206H76.5c-10.217 0-18.5 8.283-18.5 18.5S66.283 243 76.5 243h74c10.217 0 18.5-8.283 18.5-18.5s-8.283-18.5-18.5-18.5h-18.667c-1.221 9.035-8.964 16-18.333 16-9.369 0-17.112-6.965-18.332-16Z"
    fill="#fff"
  /><path
    d="M169.167 206H150.5c-10.217 0-18.5 8.283-18.5 18.5s8.283 18.5 18.5 18.5h74c10.217 0 18.5-8.283 18.5-18.5s-8.283-18.5-18.5-18.5h-18.667c-1.221 9.035-8.964 16-18.333 16-9.369 0-17.112-6.965-18.333-16Z"
    fill="#fff"
  /></svg>
</template>
